export const environment = {
  // ========== TEST SERVER =========================
  //  apiUrl: 'http://192.168.102.243:7000',
  //  imgUrl: 'http://192.168.102.243:7000/images/',

  // ========== LIVE =========================
  // apiUrl: 'https://api.simplepos.xyz',
  // imgUrl: 'https://api.simplepos.xyz/images/',
  // sampleImgUrl: 'https://blob.simplepos.xyz/imageLibrary/',

  // ========== DEMO =========================
  apiUrl: 'https://demoapi.simplepos.xyz',
  imgUrl: 'https://demoblob.simplepos.xyz/',
  sampleImgUrl: 'https://demoblob.simplepos.xyz/imageLibrary/',

  production: true,
};
